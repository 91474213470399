import RRTphoto from "./imgs/RRT2.jpg";
import ACSphoto from "./imgs/ACS.jpg";
import GRZphoto from "./imgs/GRZ.jpg";
import VRRphoto from "./imgs/VRR.jpg";
import JOGphoto from "./imgs/JOG.jpg";
import PZMphoto from "./imgs/PZM.jpg";
import NSMphoto from "./imgs/NSM.jpg";
import TNLphoto from "./imgs/TNL.jpg";
import MRphoto from "./imgs/MR.jpg";

export const coFounders = [
  {
    photo: RRTphoto,
    photoAlt: "foto Rodrigo Riquelme",
    name: "Rodrigo A. Riquelme Tapia",
    info: "Quisque sodales, libero ut molestie posuere, tortor metus finibus magna, euismod pharetra ante enim a nisl. Donec placerat elementum est, ut maximus augue",
  },
  {
    photo: GRZphoto,
    photoAlt: "foto Gonzalo Rojas",
    name: "Gonzalo A. Rojas Zúñiga",
    info: "Quisque sodales, libero ut molestie posuere, tortor metus finibus magna, euismod pharetra ante enim a nisl. Donec placerat elementum est, ut maximus augue",
  },
  {
    photo: ACSphoto,
    photoAlt: "foto Alejandro Cáceres",
    name: "Alejandro M. Cáceres Saavedra",
    info: "Quisque sodales, libero ut molestie posuere, tortor metus finibus magna, euismod pharetra ante enim a nisl. Donec placerat elementum est, ut maximus augue",
  },
];

export const teamRow1 = [
  {
    photo: VRRphoto,
    photoAlt: "foto Victor Riquelme",
    name: "Victor I. Riquelme Rojas",
    info: "Quisque sodales,",
  },

  {
    photo: MRphoto,
    photoAlt: "foto Melissa Rodríguez",
    name: "Melissa Rodríguez Vigil",
    info: "Quisque sodales,",
  },
  {
    photo: PZMphoto,
    photoAlt: "foto Patricio Zúñiga",
    name: "Patricio A. Zúñiga Muñoz",
    info: "Quisque sodales,",
  },
];

export const teamRow2 = [
  {
    photo: TNLphoto,
    photoAlt: "foto Tamara Namuncura",
    name: "Tamara P. Namuncura Luque",
    info: "Quisque sodales,",
  },
  {
    photo: JOGphoto,
    photoAlt: "foto Javier Ortiz",
    name: "Javier E. Ortiz Guzmán",
    info: "Quisque sodales,",
  },

  {
    photo: NSMphoto,
    photoAlt: "foto Natalia Sepúlveda",
    name: "Natalia E. Sepúlveda Mora",
    info: "Quisque sodales,",
  },
];
