import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Image from "react-bootstrap/Image";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import iconToUse from "./imgs/bars.svg";
import logo from "./imgs/isotipo_Mr2Tech.png";
import logoCopecUC from "./imgs/logo-copec-uc.png";
import logoUnivChile from "./imgs/Logo-UChile-gris-sf.png";
import logoStartupCienca from "./imgs/logo-startup-ciencia.png";
import logoMineriaActiva from "./imgs/logo-minería-activa.png";
import logoGeoInnova from "./imgs/logo-geoinnova-negro.png";
import { coFounders, teamRow1, teamRow2} from "./constants";
import ScrollspyNav from "react-scrollspy-nav";




function App() {
  return (
    <>
      <ScrollspyNav
        scrollTargetIds={["start", "what", "how", "team","brochure"]}
        activeNavClass="active"
        scrollDuration="10"
        headerBackground="true"
        offset={-56}
      >
        <Navbar expand="lg" fixed="top">
          <Container fluid>
            <Navbar.Brand href="#start">
              <img src={logo} alt="logo" height={60} />
            </Navbar.Brand>
            <p>
              MR2 Tech <br></br>Mineral Resources & Mineral Reserves Technology
            </p>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <img src={iconToUse} alt="Icono para abrir menu" />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="#start">Inicio</Nav.Link>
                <Nav.Link href="#what">MR2</Nav.Link>
                <Nav.Link href="#how">Cómo funciona</Nav.Link>
                <Nav.Link href="#team">Equipo</Nav.Link>
                <Nav.Link href="#brochure">Brochure</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </ScrollspyNav>
      <Container fluid data-bs-spy="scroll">
        <Row className="startSpace" id="start">
          </Row>
        <Row className="coflowSpace" id="what">
          <Col md lg={{ span: 8, offset: 2 }}>
            <div className="d-flex flex-column justify-content-center h-100">
              <h5>
                Mineral Resources & Mineral Reserves Technology es una empresa
                dedicada a desarrollar distintas herramientas tecnológicas
                innovadoras que buscan mejorar la toma de decisiones de la
                industria minera.
              </h5>
            </div>
          </Col>
        </Row>
        <Row className="howItWorkSpace" id="how">
          <Col md className="columnText">
            <div className="d-flex flex-column justify-content-center h-100">
              <h3>¿QUÉ ES?</h3>
              <ol>
                <li>
                  GeoGnoSim es una herramienta computacional que, mediante la
                  utilización de modelos estocásticos, genera múltiples
                  escenarios de un yacimiento mineral, con información de
                  dominios geológicos tales como litología, alteración, zonas
                  minerales, entre otras. Estos escenarios permiten cuantificar
                  la incertidumbre asociada a la interpretación geológica del
                  yacimiento, facilitando la toma de decisiones robustas a
                  eventuales desviaciones entre el modelo interpretado y la
                  realidad en las diferentes etapas de ciclo de vida de un
                  proyecto minero: exploración temprana, exploración avanzada,
                  explotación, etc
                </li>
              </ol>
              <p>
                <b>
                  {" "}
                  Valor: Entrega un resultado conformado por múltiples
                  escenarios de lo que puede llegar a ser la distribución
                  espacial de los dominios geológicos en el yacimiento.
                </b>
              </p>
            </div>
          </Col>
          <Col md className="howWorks" />
          <Row>
            <Col>
              <h3 className="text-center">AUSPICIADORES Y PILOTOS</h3>
            </Col>
          </Row>
          <Row className="sponsorsImages" id="sponsors">
            <Container>
              <Row>
                <Col>
                  <div className="d-flex justify-content-around">
                    <img
                      src={logoCopecUC}
                      alt="logoCopecUC"
                      id="sponsorImage"
                      height={130}
                    />
                    <img
                      src={logoUnivChile}
                      alt="logoUnivChile"
                      height={120}
                      id="sponsorImage"
                    />
                    <img
                      src={logoStartupCienca}
                      alt="logoStartupCiencia"
                      height={120}
                      id="sponsorImage"
                    />
                    <img
                      src={logoMineriaActiva}
                      alt="logoMineriaActiva"
                      height={110}
                      id="sponsorImage"
                    />
                    <img
                      src={logoGeoInnova}
                      alt="logoGeoInnova"
                      height={130}
                      id="sponsorImage"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Row>
        <Row className="teamSpace" id="team">
          <Col>
            <Row>
              <Col>
                <h3 className="text-center">EQUIPO DE LOGÍSTICA Y FINANZAS</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  {coFounders.map(function (data) {
                    return (
                      <Card key={data.name}>
                        <Image
                          src={data.photo}
                          alt={data.photoAlt}
                          roundedCircle
                          width={150}
                          height={150}
                        />
                        <Card.Body>
                          <Card.Title>{data.name}</Card.Title>
                          <Card.Text>{data.info}</Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col>
              <Col>
              <br></br>
              </Col>
              <Col>
                <h3 className="text-center">EQUIPO DE DESARROLLO E INVESTIGACIÓN</h3>
              </Col>
                <CardGroup className="teamDivision">
                  {teamRow1.map(function (data) {
                    return (
                      <Card key={data.name}>
                        <Image
                          src={data.photo}
                          alt={data.photoAlt}
                          roundedCircle
                          width={150}
                          height={150}
                        />
                        <Card.Body>
                          <Card.Title>{data.name}</Card.Title>
                          <Card.Text>{data.info}</Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </CardGroup>
                <Col>
                <CardGroup className="teamDivision">
                  {teamRow2.map(function (data) {
                    return (
                      <Card key={data.name}>
                        <Image
                          src={data.photo}
                          alt={data.photoAlt}
                          roundedCircle
                          width={150}
                          height={150}
                        />
                        <Card.Body>
                          <Card.Title>{data.name}</Card.Title>
                          <Card.Text>{data.info}</Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </CardGroup> 
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row id="brochure">
          <Col>
            <h3 className="text-center">Brochure Mr2 - GeoGnoSim</h3>
          </Col>
        </Row>
        <Row className="brochureMr2">
        <iframe src="https://geoinnova.cl/wp-content/uploads/2024/03/brochure-mr2.pdf" title="Doc" width="100%" height="100%"></iframe>
        </Row>
        <Row className="footer">
          <Col className="d-flex align-items-center justify-content-center">
            <p>© 2024 MR2. Todos los derechos reservados.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
